/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { withNoHistoryRouter } from '../withCustomRouter'
import { CACHEDMODELID, CONFIG, SITE_ID } from '../../selectors'
import QueryBuilder from './QueryBuilder'


const generateTitle = (config, match, model, site_id) => {
  const { singlekey, fields, domain, modelname, crumbclass } = config
  const { action } = match.params
  const field = fields ? fields.find(f => isEqual(f.name, singlekey)) : null
  let titleModel = model
  let pageTitle = null
  if (!model) { return false }
  if (!field && !Array.isArray(singlekey)) { return <span className="title">{getIn(model, singlekey)}</span> }
  /*
    * If the singlekey matches a field in the model meta,
    * fetch the title data from a meta model instead of the
    * original model
  */
  if (field.modelname && field.relatedfield) {
    if (model.meta && model.meta[field.modelname]) {
      if (Array.isArray(model.meta[field.modelname])) {
        titleModel = model.meta[field.modelname][0]
      } else {
        titleModel = model.meta[field.modelname]
      }
    }
  }
  pageTitle = getIn(titleModel, singlekey)
  if (Array.isArray(singlekey)) { // Join and return
    pageTitle = singlekey.map(key => getIn(titleModel, key)).join(' ')
  }
  if (action && ![ 'syndication' ].includes(domain)) {
    return (
      <NavLink
        className={crumbclass ? `${crumbclass}` : null}
        to={`/secure/${site_id}/${modelname}/${model.id}`}
      >
        {pageTitle}
      </NavLink>
    )
  }
  return <span className="title">{pageTitle}</span>
}

const Crumb = ({ match, title, suffix, config, site_id, model }) => {
  if (model && !config.modelname) { return null }
  const { action } = match.params
  const { domain, plural, singular, modelname } = config
  const pagetitle = title ? title : generateTitle(config, match, model, site_id)
  const qs = new QueryBuilder(`/secure/${site_id}/${modelname}`)
  if (config.params) {
    qs.setParam(config.params)
  }
  const parts = [
    domain || 'Dashboard',
    plural && ((domain && (title || action || pagetitle)) ? (
      <NavLink
        to={qs.url(false)}>{plural}
      </NavLink>
    ) : (
      <span>{plural}</span>
    )),
    pagetitle ? pagetitle : null,
    action === 'edit' ? <span>Edit { singular }</span> : null,
    action === 'add' ? <span>Add { singular }</span> : null,
    action === 'duplicate' ? <span>Duplicate { singular }</span> : null,
    action === 'brochure' ? <span>Generate Brochure</span> : null,
    action === 'seller-feedback' ? <span>Seller Feedback</span> : null,
    action === 'landlord-feedback' ? <span>Landlord Feedback</span> : null,
    action === 'signature' ? <span>Email Signature</span> : null,
    action === 'bulkedit' ? <span>Bulk Edit</span> : null,
    action === 'reports' ? <span>Reports</span> : null,
    suffix ? <span>{ suffix }</span> : null
  ]

  return (
    <div className="crumb">
      {parts.filter(p => p).map((p, idx) => <React.Fragment key={`crumb-${idx}`}>{p}</React.Fragment>)}
    </div>
  )
}

Crumb.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  config: PropTypes.object,
  model: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  match: PropTypes.object,
  site_id: PropTypes.number,
  suffix: PropTypes.string,
  editmode: PropTypes.bool,
  addmode: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  let modelname = getIn(ownProps, 'match.params.model')
  if (modelname === 'syndication') {
    modelname = `syndication${getIn(ownProps, 'match.params.log')}`
  }
  if (modelname === 'email-templates') {
    modelname = 'marketing-emails'
  }
  const config = CONFIG(state, modelname)
  const model = config ? CACHEDMODELID(state, config.get('modelname'), getIn(ownProps, 'match.params.id')) : null
  return {
    site_id: SITE_ID(state),
    config,
    model
  }
}

export default withNoHistoryRouter(connect(mapStateToProps, null)(withImmutablePropsToJS(Crumb)))
